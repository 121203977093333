import {procurementReducer} from './procurement/reducer'
import {searchReducer} from './search/reducer'
import {authReducer} from './auth/reducer'
import {themeReaducer} from './theme/reducer'
import {combineReducers} from 'redux'
import {RootReducer} from './types'
import {basketReducer} from './basket/reducer'
import {generateReducer} from './generate-table/reducer'

export const rootReducer = combineReducers<RootReducer>({
  search: searchReducer,
  auth: authReducer,
  theme: themeReaducer,
  basket: basketReducer,
  generate: generateReducer,
  procurement: procurementReducer,
})

import {Table} from 'antd'
import React, {useCallback, useEffect, useMemo} from 'react'
import {useSelector} from 'react-redux'
import {useAppDispatch} from '../../../hooks/storeHooks'
import {useLocalStorage} from '../../../hooks/useLocalStorage/useLocalStorage'
import {
  ProcurementItem,
  loadProcurementTableData,
  setProcurementTableSelectedRowIds,
} from '../../../store/procurement/reducer'
import {selectProcurementTable} from '../../../store/procurement/selectors'
import {isNumber} from '../../../utils/number'
import {caseInsensitiveSubstr} from '../../../utils/string'
import {useProcurementTableColumnsHook} from './hooks/use-procurement-table-columns.hook'

export function ProcurementTable() {
  const dispatch = useAppDispatch()
  const {selectedRowKeys, data, loading, searchValue} = useSelector(selectProcurementTable)
  const {setToLocalStorage} = useLocalStorage('procurement')
  const initialData = useInitialData()

  useEffect(() => {
    dispatch(loadProcurementTableData())
  }, [dispatch])

  const filteredData = useMemo(() => (searchValue
    ? data.filter(record => isMatchingRecord(record, searchValue))
    : data),
  [data, searchValue],
  )

  const onSelectionChange = useCallback((ids: Array<string>) => {
    dispatch(setProcurementTableSelectedRowIds(ids))
  }, [dispatch])

  const columns = useProcurementTableColumnsHook()

  return <Table
    loading={loading}
    dataSource={filteredData}
    columns={columns}
    size='small'
    pagination={{
      defaultCurrent: initialData.paginationPage,
      defaultPageSize: initialData.paginationSize,
      style: { display: 'flex', alignItems: 'center' },
      onChange: (page, pageSize) => {
        setToLocalStorage('pagination-page', page)
        setToLocalStorage('pagination-size', pageSize)
      },
    }}
    scroll={{ y: 640 }}
    rowSelection={{
      type: 'checkbox',
      onChange: onSelectionChange as (ids: Array<React.Key>) => void,
      selectedRowKeys,
      fixed: 'left',
      columnWidth: 46,
    }}
  />
}

function useInitialData() {
  const {getFromLocalStorage} = useLocalStorage('procurement')
  const rawPaginationPage = getFromLocalStorage('pagination-page')
  const rawPaginationSize = getFromLocalStorage('pagination-size')
  return {
    paginationPage: rawPaginationPage && isNumber(rawPaginationPage) ? Number(rawPaginationPage) : 1,
    paginationSize: rawPaginationSize && isNumber(rawPaginationSize) ? Number(rawPaginationSize) : 50,
  }
}

function isMatchingRecord(record: ProcurementItem, searchValue: string): boolean {
  for (const [key, value] of Object.entries(record)) {
    if (key === 'key' || !value) {
      continue
    }
    if (caseInsensitiveSubstr(value.toString(), searchValue)) {
      return true
    }
  }
  return false
}
import {Button} from 'antd'
import React from 'react'
import {CloseIcon} from '../icons/close-icon'
import {joinStrings} from '../../utils/string'
import Loading from '../loading/loading'

interface DefaultButtonsProps {
	onCancel: () => void,
	onSuccess: () => void,
	cancelText?: string,
	successText?: string,
}

interface PopupDefaultContentProps {
	title?: string,
	createContent: () => JSX.Element,
	buttonsInfo: DefaultButtonsProps | Array<JSX.Element>,
	closeFn?: () => void,
	className?: string,
	status?: 'normal'|'loading'
	style?: React.CSSProperties,
}

export function PopupDefaultWrapper({
  title,
  createContent,
  buttonsInfo,
  className,
  closeFn,
  status = 'normal',
  style,
}: PopupDefaultContentProps) {
  const hasHeaderProps = !!title || !!closeFn
  const isLoading = status === 'loading'
  const buttons = Array.isArray(buttonsInfo)
    ? buttonsInfo
    : [
      <Button key='cancel' type='default' shape='round' size='small'
        onClick={buttonsInfo.onCancel} className='cancel-button'>
        {buttonsInfo.cancelText || 'Отменить'}
      </Button>,
      <Button key='success' type='default' shape='round' size='small' disabled={isLoading}
        onClick={buttonsInfo.onSuccess} className='success-button'>
        {buttonsInfo.successText || 'Сохранить'}
      </Button>,
    ]

  return (
    <div style={style} className={joinStrings('popup-default-wrapper', className)}>
      {hasHeaderProps &&
        <div className='header'>
          {title && <h3 className='title'>{title}</h3>}
          {closeFn &&
            <div className='cross' onClick={closeFn}>
              <CloseIcon />
            </div>
          }
        </div>
      }
      {isLoading ? <Loading /> : createContent()}
      {!isLoading &&
        <div className='buttons-section'>
          {buttons}
        </div>
      }
    </div>
  )
}
import React from 'react'
import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import {joinStrings} from '../../utils/string'

const Icon = <LoadingOutlined style={{ fontSize: 35 }} spin />

type LoadingType = {
  cover?: 'page'
}

const Loading: React.FC<LoadingType> = ({
  cover,
}) => (
  <div className={joinStrings('loading d-flex justify-content-center align-items-center', cover && `cover-${cover}`)}>
    <Spin indicator={Icon} />
  </div>
)

export default Loading
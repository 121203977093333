import React, {useState} from 'react'
import {SettingOutlined} from '@ant-design/icons'
import {Drawer, Layout, Menu} from 'antd'
import {isProdHostname} from '../../api/apiCreator'
import {DIR_RTL} from '../../constants/ThemeConstant'
import {useSelector} from 'react-redux'
import {selectDirection} from '../../store/theme/selectors'
import {ThemeConfigurator} from '../configurator'

interface DefaultAppHeaderProps {
  actionButtons?: Array<JSX.Element>,
}

export function DefaultAppHeader({
  actionButtons,
}: DefaultAppHeaderProps) {
  const direction = useSelector(selectDirection)
  const [visible, setVisible] = useState<boolean>(false)
  return (
    <Layout.Header className='default-app-header'>
      {!!actionButtons && !!actionButtons.length &&
        <div className='d-flex align-items-center'>
          {actionButtons}
        </div>
      }
      {!isProdHostname() && <span className='dev-text'>DEV</span>}
      <Menu mode='horizontal'>
        <Menu.Item key='panel' onClick={() => setVisible(true)}>
          <span><SettingOutlined className='nav-icon mr-0' /></span>
        </Menu.Item>
      </Menu>
      <Drawer
        title='Configuration'
        placement={direction === DIR_RTL ? 'left' : 'right'}
        width={350}
        onClose={() => setVisible(false)}
        visible={visible}
      >
        <ThemeConfigurator/>
      </Drawer>
    </Layout.Header>
  )
}

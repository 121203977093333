import React from 'react'
import {Layout} from 'antd'
import {DefaultAppHeader} from '../components/nav-panel'
import {useCheckAuth} from '../hooks/useCheckAuth'

export function withDefaultAppLayout(children: Array<React.ReactNode>|React.ReactNode, specificStyleClassname = '') {
  return <DefaultAppLayout specificStyleClassname={specificStyleClassname}>{children}</DefaultAppLayout>
}

type DefaultAppLayoutType = {
  specificStyleClassname: string
}

const DefaultAppLayout: React.FC<DefaultAppLayoutType> = props => {
  useCheckAuth()

  return (
    <Layout className={`default-app-layout ${props.specificStyleClassname}`}>
      <DefaultAppHeader />
      <Layout.Content className='main'>
        {props.children}
      </Layout.Content>
    </Layout>
  )
}

import {ColumnFilterItem} from 'antd/lib/table/interface'
import {useMemo} from 'react'
import {useSelector} from 'react-redux'
import {selectProcurementTableData} from '../../../../store/procurement/selectors'
import {caseInsensitiveCompare} from '../../../../utils/compare'
import {ProcurementTableColumnId} from './use-procurement-table-columns.hook'

type FilteredColumnId = Extract<ProcurementTableColumnId, 'clientName'|'amoId'|'supposedStore'|'brand'|'country'>
type ProcurementTableFilters = Record<FilteredColumnId, Array<ColumnFilterItem>>

export function useProcurementTableFiltersHook(): ProcurementTableFilters {
  const data = useSelector(selectProcurementTableData)

  return useMemo(() => {
    const clientNameValuesSet = new Set<string>()
    const amoIdValuesSet = new Set<string>()
    const storeNameValuesSet = new Set<string>()
    const brandsSet = new Set<string>()
    const countriesSet = new Set<string>()

    data.forEach(item => {
      item.clientName && clientNameValuesSet.add(item.clientName)
      item.amoId && amoIdValuesSet.add(item.amoId)
      item.supposedStore && storeNameValuesSet.add(item.supposedStore)
      item.brand && brandsSet.add(item.brand)
      item.country && countriesSet.add(item.country)
    })

    return {
      clientName: convertValuesSetToSortedFiltersArray(clientNameValuesSet),
      amoId: convertValuesSetToSortedFiltersArray(amoIdValuesSet),
      supposedStore: convertValuesSetToSortedFiltersArray(storeNameValuesSet),
      brand: convertValuesSetToSortedFiltersArray(brandsSet),
      country: convertValuesSetToSortedFiltersArray(countriesSet),
    }
  }, [data])
}

function convertValuesSetToSortedFiltersArray(set: Set<string>): Array<ColumnFilterItem> {
  const array: Array<ColumnFilterItem> = []
  array.push({
    text: 'Пусто',
    value: '',
  })
  set.forEach(value => {
    array.push({text: value, value})
  })
  array.sort((a, b) => caseInsensitiveCompare(a.value as string, b.value as string))
  return array
}
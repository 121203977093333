import { createSlice} from '@reduxjs/toolkit'
import {ActionType} from '../types'

type InitialStateType = {
	auth: {
		email: string,
		password: string
	}
	token: string
	loading: boolean
}

type EmailAction = ActionType<string>

const initialState: InitialStateType = {
  auth:{
    email: '',
    password: '',
  },
  token:'',
  loading: true,
}

const authSlice = createSlice({
  name: 'auth',
  initialState: {...initialState},
  reducers: {
    authAction() {
    },
    setEmailAction(state, action: EmailAction) {
      state.auth.email = action.payload
    },
    removeEmailAction() {

    },
  },
})

export const authReducer = authSlice.reducer
export const {
  authAction,
  setEmailAction,
} = authSlice.actions

export class Logger {
  static error(message?: string) {
    console.error(message || 'Logger catch error')
  }

  static assert(condition: unknown, message = 'Assertion failed') {
    !condition && Logger.error(message)
  }
}

export function verify<T>(condition: (T|null|undefined), message?: string): T {
  Logger.assert(condition, message)
  return condition as T
}
import {useSelector} from 'react-redux'
import {DefaultAppHeader} from '../components/nav-panel/index'
import {PieChartOutlined} from '@ant-design/icons'
import React from 'react'
import {Layout, Menu} from 'antd'
import {useCheckAuth} from '../hooks/useCheckAuth'
import {selectProcurementTableSelectedRowKeys} from '../store/procurement/selectors'
import {CreateOrderButton} from '../views/app-views/procurement-view/create-order-button'

export function withProcurementLayout(children: Array<React.ReactNode>|React.ReactNode) {
  return <ProcurementLayout>{children}</ProcurementLayout>
}

const ProcurementLayout: React.FC = props => {
  useCheckAuth()
  const selectedRowKeys = useSelector(selectProcurementTableSelectedRowKeys)
  const actionButtons = selectedRowKeys.length > 0
    ? [<CreateOrderButton key='create' />]
    : []

  return (
    <Layout className='procurement-layout'>
      <DefaultAppHeader actionButtons={actionButtons}/>
      <Layout>
        <ProcurementSideBar />
        <Layout.Content className='main'>
          {props.children}
        </Layout.Content>
      </Layout>
    </Layout>
  )
}

function ProcurementSideBar() {
  return (
    <Layout.Sider className='sider' width={72} collapsed={true}>
      <Menu mode='inline' style={{ height: '100%', borderRight: 0 }}>
        <Menu.Item key='procurement'>
          <PieChartOutlined />
        </Menu.Item>
      </Menu>
    </Layout.Sider>
  )
}

import React from 'react'
import { ThemeSwitcherProvider } from 'react-css-theme-switcher'
import { THEME_CONFIG } from './config/AppConfig'
import { Views} from './views'

const themes = {
  dark: `${process.env.PUBLIC_URL}/css/dark-theme.css`,
  light: `${process.env.PUBLIC_URL}/css/light-theme.css`,
}

function App() {

  return (
    <div className='App'>
      <ThemeSwitcherProvider themeMap={themes} defaultTheme={THEME_CONFIG.currentTheme} insertionPoint='styles-insertion-point'>
        <Views/>
      </ThemeSwitcherProvider>
    </div>
  )
}

export default App

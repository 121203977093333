import React from 'react'
import {Button, Switch} from 'antd'
// import {
//   onDirectionChange,
//   onHeaderNavColorChange,
//   onNavStyleChange,
//   onNavTypeChange,
//   onSwitchTheme,
//   onTopNavColorChange,
//   toggleCollapsedNav
// } from 'redux/actions/Theme';
//import NavLanguage from './NavLanguage';
import {useThemeSwitcher} from 'react-css-theme-switcher'
import {setHeaderNavColor, setTheme} from '../../store/theme/reducer'
import {useHistory} from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {selectTheme} from '../../store/theme/selectors'
import {AUTH_TOKEN} from '../../config/AppConfig'

//@ts-ignore
const ListOption = ({name, selector, disabled, vertical}) => (
  <div className={`my-4 ${vertical ? '' : 'd-flex align-items-center justify-content-between'}`}>
    <div className={`${disabled ? 'opacity-0-3' : ''} ${vertical ? 'mb-3' : ''}`}>{name}</div>
    <div>{selector}</div>
  </div>
)

export const ThemeConfigurator = () => {
  const dispatch = useDispatch()
  const {switcher, themes} = useThemeSwitcher()
  const currentTheme = useSelector(selectTheme)

  const toggleTheme = (isChecked: boolean) => {
    dispatch(setHeaderNavColor(''))
    const changedTheme = isChecked ? 'dark' : 'light'
    dispatch(setTheme(changedTheme))
    localStorage.setItem('theme', changedTheme)
    switcher({ theme: themes[changedTheme] })
  }

  // const ontopNavColorClick = (value) => {
  // 	onHeaderNavColorChange('')
  // 	const { rgb } = value
  // 	const rgba = `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${rgb.a})`
  // 	const hex = utils.rgbaToHex(rgba)
  // 	onTopNavColorChange(hex)
  // }
  // const onHeaderNavColorClick = (value) => {
  // 	const { rgb } = value
  // 	const rgba = `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${rgb.a})`
  // 	const hex = utils.rgbaToHex(rgba)
  // 	onHeaderNavColorChange(hex)
  // }

  // const onNavTypeClick = (value) => {
  // 	onHeaderNavColorChange('')
  // 	if(value === NAV_TYPE_TOP) {
  // 		onTopNavColorChange(colorOptions[0])
  // 		toggleCollapsedNav(false)
  // 	}
  // 	onNavTypeChange(value)
  // }

  const history = useHistory()

  const doSignOut = () => {
    localStorage.removeItem(AUTH_TOKEN)
    history.push('/auth/login')
  }

  return (
    <>
      <div className='mb-5'>
        <h4 className='mb-3 font-weight-bold'>Navigation</h4>
        {/* @ts-ignore */}
        <ListOption
          name='Dark Theme:'
          selector={
            <Switch checked={currentTheme === 'dark'} onChange={toggleTheme} />
          }
        />
      </div>
      <div className='mb-5'>
        {/* <h4 className="mb-3 font-weight-bold">Locale</h4> */}
        {/* @ts-ignore */}
        {/* <ListOption
					name="Language:"
					selector={
						<NavLanguage configDisplay/>
					}
				/> */}
      </div>
      <div className='mb-5'>
        <Button onClick={() => doSignOut()} type='primary'>Выйти</Button>
      </div>
    </>
  )
}

// const mapStateToProps = ({ theme }) => {
//   const { navType, sideNavTheme, navCollapsed, topNavColor, headerNavColor, locale, currentTheme, direction } =  theme;
//   return { navType, sideNavTheme, navCollapsed, topNavColor, headerNavColor, locale, currentTheme, direction }
// };

// const mapDispatchToProps = {
// 	toggleCollapsedNav,
// 	onNavTypeChange,
// 	onNavStyleChange,
// 	onTopNavColorChange,
// 	onHeaderNavColorChange,
// 	onSwitchTheme,
// 	onDirectionChange
// }

// export default connect(mapStateToProps, mapDispatchToProps)(Configurator)

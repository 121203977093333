import {format, getYear} from 'date-fns'
import {useSelector} from 'react-redux'
import {LinesEllipsisWithTooltip} from '../../../../components/lines-ellipsis-with-tooltip/lines-ellipsis-with-tooltip'
import {selectProcurementTableSearchValue} from '../../../../store/procurement/selectors'

interface InvoiceDateCellProps {
	timestamp: number|null,
}

export function InvoiceDateCell({
  timestamp,
}: InvoiceDateCellProps) {
  const searchValue = useSelector(selectProcurementTableSearchValue)
  const currentYear = new Date().getFullYear()
  const stringDate = timestamp === null
    ? ''
    : format(timestamp, getYear(timestamp) === currentYear ? 'dd.MM' : 'yyyy.MM.dd')

  return <LinesEllipsisWithTooltip text={stringDate} words={[searchValue]} />
}
import {SagaIterator} from 'redux-saga'
import {call, put, takeEvery} from 'redux-saga/effects'
import {ARType, getApi} from '../../api/getApi'
import {defaultError} from '../../utils/errors'
import {loadProcurementTableData, setProcurementTableData, setProcurementTableLoading} from './reducer'

export function *getProcurementDataSaga(): SagaIterator {
  yield takeEvery(loadProcurementTableData, sagaWorker)
}

function *sagaWorker(): SagaIterator {
  try {
    const getFn = getApi().procurement.getTableData
    yield put(setProcurementTableLoading(true))
    const data: ARType<typeof getFn> = yield call(getFn)
    yield put(setProcurementTableData(data))
    yield put(setProcurementTableLoading(false))

  }
  catch (e) {
    defaultError({
      errorObj: e,
      consoleMessage: 'Не удалось получить данные для ProcurementTable',
    })
  }
}
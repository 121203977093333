import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import {configureDev} from './configureDev'
import reportWebVitals from './reportWebVitals'
import {Provider} from 'react-redux'
import {store} from './store/store'
import {BrowserRouter} from 'react-router-dom'

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
)

if (process.env.NODE_ENV === 'development') {
  configureDev()
}


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

import {createSelector} from '@reduxjs/toolkit'
import {RootState} from '../types'

const selectSearch = (state: RootState) => state.search

export const selectInitialValue = createSelector(selectSearch, state => state.initialValue)
export const selectCities = createSelector(selectSearch, state => state.cities)
export const selectProducts = createSelector(selectSearch, state => state.products)
export const selectHandleInputProduct = createSelector(selectSearch, state => state.handleInputProduct)
export const selectAnalogs = createSelector(selectSearch, state => state.analogs)

import {ProcurementLocalStorage} from './types'

export interface ErpStorage {
	procurement: ProcurementLocalStorage,
}

type StorageKeys<T extends keyof ErpStorage> = keyof ErpStorage[T]
type KeyValue<Storage extends keyof ErpStorage, Key extends keyof ErpStorage[Storage]> = ErpStorage[Storage][Key]

export function useLocalStorage(storageName: keyof ErpStorage) {
  const generateKey = (key: string) => `erp_${storageName}_${key}`

  function setToLocalStorage(keyName: StorageKeys<typeof storageName>, value: KeyValue<typeof storageName, typeof keyName>) {
    localStorage.setItem(generateKey(keyName), value.toString())
  }

  function getFromLocalStorage(keyName: StorageKeys<typeof storageName>): string | null {
    return localStorage.getItem(generateKey(keyName))
  }

  return {
    setToLocalStorage,
    getFromLocalStorage,
  }
}
import {attach} from './attach/attach'
import {auth} from './auth/auth'
import {casId} from './cas-id/cas-id'
import {procurement} from './procurement/procurement'
import {proposal} from './proposal/proposal'
import {spreadsheet} from './spreadsheet/spreadsheet'
import {users} from './users/users'
import {vendors} from './vendors/vendors'

export function getApi() {
  return {
    users,
    attach,
    auth,
    casId,
    procurement,
    proposal,
    spreadsheet,
    vendors,
  }
}

export type ARType<T extends (...args: unknown[]) => unknown> = Awaited<ReturnType<T>>
import React from 'react'

type PageHeaderType = {
    title: string
    display: boolean
}

export const PageHeader: React.FC<PageHeaderType> = props => {
  const {title, display} = props

  return (
    display ? <div className='page-header-alt border-bottom mb-4'>
      <h3 className='mb-0 mr-3 font-weight-semibold'>
        {title ? title : 'home'}
      </h3>
    </div> : <></>
  )
}

import React, {useEffect} from 'react'
import {Button, Card, Form, Input, Radio, notification} from 'antd'
import {useDispatch} from 'react-redux'
import {getApi} from '../../../api/getApi'
import {setEmailAction} from '../../../store/auth/reducer'
import {PageHeader} from '../../../components/page-header'
import {useHistory} from 'react-router-dom'

const layout = {
  labelCol: {
    span: 3,
  },
  wrapperCol: {
    span: 12,
  },
}

const tailLayout = {
  wrapperCol: {
    offset: 3,
    span: 12,
  },
}

export const AddUserView: React.FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    dispatch(setEmailAction(localStorage.getItem('appSceienceEmail')!))
  }, [])

  const onFinish = (e: { [key: string]: unknown; }) => {
    getApi().users.add(e).then(() => {
      notification.success({
        message: `Успешно`,
        description: 'Пользователь успешно добавлен',
      })
      setTimeout(() => { //TODO change to appropriate form clear
        history.go(0)
      }, 1000)
    })
  }

  return <>
    <PageHeader display={true} title={'Добавление пользователя'} />
    <Card>
      <Form
        name='basic'
        {...layout}
        initialValues={{
          role: 'admin',
        }}
        onFinish={onFinish}
      >
        <Form.Item
          label='Имя'
          name='first_name'
          rules={[
            {
              required: true,
              message: 'Введите имя пользователя!',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label='Фамилия'
          name='last_name'
          rules={[
            {
              required: true,
              message: 'Введите фамилию пользователя!',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label='Email'
          name='email'
          rules={[
            {
              required: true,
              type: 'email',
              message: 'Введите email пользователя!',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label='Номер телефона'
          name='phone_number'
          rules={[
            {
              required: true,
              message: 'Введите номер телефона!',
            },
            {
              min: 12,
              max: 12,
              pattern: /^\+\d{11,15}$/,
              message: 'Введите телефон в формате +79201234567',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label='Роль'
          name='role'
          rules={[
            {
              required: true,
              message: 'Введите номер телефона!',
            },
          ]}
        >
          <Radio.Group>
            <Radio.Button value='admin'>Админ</Radio.Button>
            <Radio.Button value='user'>Пользователь</Radio.Button>
          </Radio.Group>
        </Form.Item>

        <Form.Item {...tailLayout}>
          <Button type='primary' htmlType='submit'>
                                Добавить
          </Button>
        </Form.Item>
      </Form>
    </Card>
  </>
}

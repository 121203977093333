import {ProcurementItem} from '../../store/procurement/reducer'
import {parseStringDateToTimestamp} from '../../utils/date'
import {Logger} from '../../utils/Logger'
import {isNumber} from '../../utils/number'
import {ProcurementTableResponseData} from './procurement'

export function remapToProcurementItem(data: ProcurementTableResponseData): ProcurementItem {
  const quantityIsValid = isNumber(data.quantity)
  const quantity = quantityIsValid ? Number(data.quantity) : 0
  if (!quantityIsValid) {
    Logger.error(`Quantity is not a number. Value: ${data.quantity}`)
  }

  return {
    key: data.id,
    invoiceTimestamp: parseStringDateToTimestamp(data.date),
    deadlineTimestamp: parseStringDateToTimestamp(data.deadline_date),
    invoiceName: data.name,
    amoId: data.deal_id,
    clientName: data.client_name,
    clientPrice: data.final_price,
    supposedStore: data.store,
    brand: data.brand,
    purchaseTimestamp: parseStringDateToTimestamp(data.proc_deadline_date),
    country: data.country,
    catalogueId: data.catalogue_id,
    quantity,
    proposalComment: data.proposal_comments,
  }
}
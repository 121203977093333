import {Modal} from 'antd'
import React from 'react'
import {useSelector} from 'react-redux'
import {selectShowProcurementCreateOrderPopup} from '../../../../../store/procurement/selectors'
import {CreateProcurementOrderPopupContent} from './content'

export function CreateProcurementOrderPopup() {
  const show = useSelector(selectShowProcurementCreateOrderPopup)

  return <Modal
    visible={show}
    centered={true}
    modalRender={() => <CreateProcurementOrderPopupContent />}
    destroyOnClose={true}
  />
}
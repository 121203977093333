import {useWindowSize} from '../useWindowSize'

export enum ScreenSize {
	Unknown,
	Mobile,
	Tablet,
	Desktop
}

//the size of the display width, which determines the design for mobile and desktop devices
export const widthBreakpoint = 959

export const useScreenSize = (): ScreenSize => {
  const {
    width,
  } = useWindowSize()

  let _deviceType = ScreenSize.Unknown


  if (width > widthBreakpoint) {
    _deviceType = ScreenSize.Desktop
  }
  else {
    _deviceType = ScreenSize.Mobile
  }

  return _deviceType
}

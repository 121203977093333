import React, {useEffect, useRef} from 'react'
import {Card} from 'antd'
import {LoadingOutlined} from '@ant-design/icons'
import {BasketProductCard} from '../../components/basket-product-card'
import {useBasket} from './basket.hook'
import {Scrollbars} from 'react-custom-scrollbars'

export const BasketContent: React.FC = () => {
  const {
    //props
    basketProducts,
    loadingBasket,
    isVisibleLoader,

    //methods
    onCountHandler,
    initState,
    onStartEditHandler,
  } = useBasket()
  useEffect(initState, [])
  //
  const ref = useRef(null)
  const ref_1 = useRef(null)
  const ref_2 = useRef(null)
  //
  const scrollTrigger = () => {
    //@ts-ignore
    ref.current && ref.current.scrollTop(ref.current!.getScrollHeight())
    //@ts-ignore
    ref_1.current && ref_1.current.scrollTop(ref_1.current!.getScrollHeight())
    //@ts-ignore
    ref_2.current && ref_2.current.scrollTop(ref_2.current!.getScrollHeight())
  }
  //
  const cardRefTop = useRef<HTMLDivElement>(null)
  const cardRefBottom = useRef<HTMLDivElement>(null)

  if (isVisibleLoader) {
    return <div>
      {loadingBasket ? <Scrollbars
        autoHeight
        ref={ref}
        autoHeightMin={0}
        autoHeightMax={400}>
        <Card bordered={true}>
          <div className='d-flex justify-content-center'>
            <LoadingOutlined style={{fontSize: 24}}/>
          </div>
        </Card>
      </Scrollbars>
        :	basketProducts.length
          ? <>
            <h3>Корзина</h3>
            <Scrollbars
              ref={ref_1}
              autoHeight
              autoHeightMin={0}
              autoHeightMax={400}>
              <div ref={cardRefTop}>
                <Card bordered={true}>
                  <div className='test'>
                    {basketProducts.map((item, key, itemArray) =>
                      <BasketProductCard
                        {...item}
                        scrollTrigger={scrollTrigger}
                        lastItem={key === itemArray.length - 1}
                        key={key}
                        itemIndex={key}
                        onCount={onCountHandler}
                        onStartEditHandler={onStartEditHandler}/>)}
                  </div>
                </Card>
              </div>
            </Scrollbars>
          </> : <></>}
    </div>
  }
  else {
    return basketProducts.length
      ? <>
        <h3>Корзина</h3>
        <div ref={cardRefBottom}>
          <Scrollbars
            autoHeight
            ref={ref_2}
            autoHeightMin={0}
            autoHeightMax={400}>
            <Card bordered={true} style={{overflowY: 'auto', maxHeight: 400}}>
              <div>
                {basketProducts.map((item, key, itemArray) =>
                  <BasketProductCard
                    key={key} {...item}
                    scrollTrigger={scrollTrigger}
                    itemIndex={key}
                    lastItem={key === itemArray.length - 1}
                    onCount={onCountHandler}/>)}
              </div>
            </Card>
          </Scrollbars>
        </div>
      </> : <></>
  }
}

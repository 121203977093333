import {useSelector} from 'react-redux'
import {LinesEllipsisWithTooltip} from '../../../../components/lines-ellipsis-with-tooltip/lines-ellipsis-with-tooltip'
import {selectProcurementTableSearchValue} from '../../../../store/procurement/selectors'

interface AmoIdCellProps {
	id: string,
}

function generateAMOUrl(id: string) {
  return `https://appscience.amocrm.ru/leads/detail/${id}`
}

export function AmoIdCell({
  id,
}: AmoIdCellProps) {
  const searchValue = useSelector(selectProcurementTableSearchValue)

  return <a href={generateAMOUrl(id)} target='_blank' className='amo-id-cell'>
    <LinesEllipsisWithTooltip text={id} words={[searchValue]} />
  </a>
}
import React, {useEffect, useState} from 'react'
import {Form} from 'antd'
import {getApi} from '../../api/getApi'
import {selectHandleInputProduct} from '../../store/search/selectors'
import {useDispatch, useSelector} from 'react-redux'
import {setProductsListAction} from '../../store/basket/reducer'
import {HandleInputProductType} from '../../store/search/reducer'
import {HandleInputForm} from '../../components/handle-input-form'

export const HandleInputBasketContent: React.FC = () => {
  const handleInputProduct = useSelector(selectHandleInputProduct)
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const [price_currency] = useState<string>('USD')
  //const [vendorsList, setVendorsList] = useState<string[]>([])

  // const {
  // 	name,
  // 	catalogue_id,
  // 	cas_id,
  // 	price,
  // 	//price_currency,
  // 	shipping_conditions,
  // 	package_size,
  // 	vendor,
  // 	partner_url,
  // 	count,
  // 	info_from_client
  // } = handleInputProduct

  //Price After
  // const priceAfter = (
  // 	<Select defaultValue={price_currency} style={{width: 70}} onChange={value => setCurrencyPrice(value)}>
  // 		<Option value='USD'>USD</Option>
  // 		<Option value='EUR'>EUR</Option>
  // 		<Option value='GBP'>GBP</Option>
  // 		<Option value='CHF'>CHF</Option>
  // 		<Option value='RUB'>RUB</Option>
  // 	</Select>
  // )

  useEffect(() => {
    let isMounted = true
    getApi().vendors.get().then(({data}) => {
      if (isMounted) {
        //setVendorsList(Utils.transformStringToArray(data))
      }
    })
    return () => {
      isMounted = false
    }
  }, [])


  const onFinish = (object: HandleInputProductType) => {
    const arrayData = localStorage.getItem('basketAppscience')

    if (arrayData) {
      const _resultArray = JSON.parse(arrayData)
      _resultArray.push({...object, price_currency})
      localStorage.setItem('basketAppscience', JSON.stringify(_resultArray))
      //@ts-ignore
      dispatch(setProductsListAction([..._resultArray]))
    }
    else {
      localStorage.setItem('basketAppscience', JSON.stringify([{...object, price_currency}]))
      //@ts-ignore
      dispatch(setProductsListAction([{...object, price_currency}]))
    }
    form.resetFields()
  }

  return (
    <HandleInputForm
      initialValues={handleInputProduct}
      onFinishHandler={onFinish}
      showFinishBtn={true}/>
  )
}

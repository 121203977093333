import React, {useEffect} from 'react'
import {useThemeSwitcher} from 'react-css-theme-switcher'
import AppLocale from '../lang'
import {IntlProvider} from 'react-intl'
import {ConfigProvider} from 'antd'
import useBodyClass from '../hooks/useBodyClass'
import {useDispatch, useSelector} from 'react-redux'
import {withProcurementLayout} from '../layouts/procurement-layout'
import {selectDirection, selectLocale} from '../store/theme/selectors'
import {setDeviceMode} from '../store/theme/reducer'
import {ProcurementView} from './app-views/procurement-view/index'
import {AuthView} from './auth-view'
import ForgotPasswordForm from '../components/forgot-pass-form'
import {Redirect, Route, Switch} from 'react-router-dom'
import {ProposalView} from './app-views/proposal-view'
import {useScreenSize} from '../hooks/useScreenSize'
import {AddUserView} from './app-views/users/add'
import {withDefaultAppLayout} from '../layouts/default-app-layout'
import ThemeLoading from '../components/loading/themeLoading'

export const Views = () => {
  const direction = useSelector(selectDirection)
  const locale = useSelector(selectLocale)
  const dispatch = useDispatch()
  const _deviceType = useScreenSize()

  useEffect(() => {
    dispatch(setDeviceMode(_deviceType))
  }, [_deviceType, dispatch])

  //@ts-ignore
  const currentAppLocale = AppLocale[locale]
  useBodyClass(`dir-${direction}`)

  const { status } = useThemeSwitcher()

  return (
    <IntlProvider locale={currentAppLocale.locale} messages={currentAppLocale.messages}>
      {/**@ts-ignore**/}
      <ConfigProvider locale={currentAppLocale.antd} direction={direction}>
        <div className='auth-container'>
          {status !== 'loaded' && <ThemeLoading />}
          <Switch>

            {/*Auth Section*/}
            <Route path='/auth/forgot' exact>
              <ForgotPasswordForm/>
            </Route>
            <Route path='/auth'>
              <AuthView/>
            </Route>
            {/*End Auth Section*/}

            <Route path='/' exact>
              <Redirect to='/proposal'/>
            </Route>
            <Route path='/proposal' exact>
              {withDefaultAppLayout(<ProposalView/>, 'proposal-page')}
            </Route>
            <Route path='/users/add' exact>
              {withDefaultAppLayout(<AddUserView/>)}
            </Route>

            <Route path='/procurement' exact>
              {withProcurementLayout(<ProcurementView />)}
            </Route>
          </Switch>
        </div>
      </ConfigProvider>
    </IntlProvider>
  )
}

import {Button, Input, Modal} from 'antd'
import {CopyOutlined, SplitCellsOutlined} from '@ant-design/icons'
import React, {useRef, useState} from 'react'
import {useSelector} from 'react-redux'
import {useAppDispatch} from '../../../hooks/storeHooks'
import {setProcurementTableSearchValue} from '../../../store/procurement/reducer'
import {selectProcurementTableSelectedRowKeys} from '../../../store/procurement/selectors'
import {SplitProcurementTableItemPopup} from './popups/split-item-popup/index'

export function ProcurementActionsSection() {
  const dispatch = useAppDispatch()
  const selectedRowIds = useSelector(selectProcurementTableSelectedRowKeys)
  const [searchValue, setSearchValue] = useState('')
  const ref = useRef<Input>(null)
  const showActions = selectedRowIds.length > 0

  return (
    <div className='d-flex mb-3'>
      <Input.Search
        ref={ref}
        type='default'
        value={searchValue}
        size='small'
        onChange={event => setSearchValue(event.currentTarget.value)}
        onSearch={value => {
          dispatch(setProcurementTableSearchValue(value))
          ref.current && ref.current.blur()
        }}
        className='mr-auto px-2'
        style={{'width': '300px'}}
      />
      {showActions && <ActionButtons />}
    </div>
  )
}

export const ActionButtons = React.memo(() => {
  const [showPopupInfo, setShowPopupInfo] = useState({
    changeResponsiblePerson: false,
    duplicateItem: false,
    splitItem: false,
  })

  function openPopup(key: keyof typeof showPopupInfo) {
    setShowPopupInfo(prev => ({...prev, [key]: true}))
  }

  function closePopup(key: keyof typeof showPopupInfo) {
    setShowPopupInfo(prev => ({...prev, [key]: false}))
  }

  const className = 'mr-2'
  return (
    <>
      <Button type='default' shape='round' size='small' icon={<CopyOutlined />} className={className}
        onClick={() => openPopup('duplicateItem')}
      >
				Дублировать
      </Button>
      <Button type='default' shape='round' size='small' icon={<SplitCellsOutlined />}
        onClick={() => openPopup('splitItem')}
      >
				Разделить строки
      </Button>
      <Modal
        modalRender={() => (
          <SplitProcurementTableItemPopup closeFn={() => closePopup('splitItem')} />
        )}
        visible={showPopupInfo.splitItem}
        centered={true}
        destroyOnClose={true}
      />
    </>
  )
})
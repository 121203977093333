import {createSlice} from '@reduxjs/toolkit'
import {ActionType} from '../types'
import {ProductType} from '../search/types'

export type ProductBasketType = ProductType & {count: number}

type InitialStateType = {
	basketProducts: ProductBasketType[]
	editedItem?: number
	openHandleEditor: boolean
}

const initialState: InitialStateType = {
  basketProducts: [],
  editedItem: undefined,
  openHandleEditor: false,
}

const basketSlice = createSlice({
  name: 'search',
  initialState: {...initialState},
  reducers: {
    setInitialStateAction(state, action: ActionType<ProductBasketType[]>) {
      state.basketProducts = action.payload
    },

    setProductsListAction(state, action: ActionType<ProductBasketType[]>) {
      state.basketProducts = [...action.payload]
    },

    setEditedItemIndex(state, action: ActionType<number>) {
      state.editedItem = action.payload
    },

    setOpenHandleEditor(state, action: ActionType<boolean>) {
      state.openHandleEditor = action.payload
    },
  },
})

export const basketReducer = basketSlice.reducer
export const {
  setInitialStateAction,
  setProductsListAction,
  setEditedItemIndex,
  setOpenHandleEditor,
} = basketSlice.actions

import {joinStrings} from '../../utils/string'
import styles from './lines-ellipsis-with-tooltip.module.css'
import {Tooltip} from 'antd'
import React, {useState} from 'react'
import Highlighter from 'react-highlight-words'
import LinesEllipsis from 'react-lines-ellipsis'

interface Props extends LinesEllipsis.ReactLinesEllipsisProps {
  words?: Array<string>,
}

export function LinesEllipsisWithTooltip({
  className,
  text: fullText,
  words,
  basedOn,
  trimRight,
  ...props
}: Props) {
  const [clampedText, setClampedText] = useState(fullText || '')
  const [isClamped, setIsClamped] = useState(false)
  const [showTooltip, setShowTooltip] = useState(isClamped)

  function getHighlightContent(searchWords: Array<string>) {
    return <>
      <Highlighter
        highlightClassName='bg-warning p-0'
        searchWords={searchWords}
        textToHighlight={clampedText}
      />
      <wbr/>
      {isClamped && '…'}
    </>
  }

  return <>
    <Tooltip
      title={fullText}
      overlayStyle={{display: showTooltip ? 'block' : 'none'}}
      onVisibleChange={visible => isClamped && setShowTooltip(visible)}
    >
      <div className={joinStrings(styles.container, className)}>
        {!!words && getHighlightContent(words)}
        <LinesEllipsis
          {...props}
          text={fullText}
          onReflow={({ clamped, text }) => {
            setIsClamped(clamped)
            setClampedText(text)
          }}
          basedOn={basedOn || 'letters'}
          trimRight={trimRight === undefined ? true : trimRight}
          className={words ? styles['lines-text_highlight'] : undefined}
        />
      </div>
    </Tooltip>
  </>
}
import {ColumnType} from 'antd/es/table/index'
import {useCallback, useMemo} from 'react'
import {ProcurementItem} from '../../../../store/procurement/reducer'
import {caseInsensitiveCompare, defaultCompare} from '../../../../utils/compare'
import {formatMoney} from '../../../../utils/number'
import {caseInsensitiveSubstr} from '../../../../utils/string'
import {AmoIdCell} from '../cells/amo-id-cell'
import {InvoiceDateCell} from '../cells/invoice-date-cell'
import {TextCell} from '../cells/text-cell'
import {useProcurementTableFiltersHook} from './use-procurement-table-filters.hook'

export type ProcurementTableColumnId = Exclude<keyof ProcurementItem, 'key'>

interface ProcurementTableColumn extends ColumnType<ProcurementItem> {
	dataIndex: ProcurementTableColumnId,
	key: ProcurementTableColumnId,
}

export function useProcurementTableColumnsHook(): Array<ProcurementTableColumn> {
  const filters = useProcurementTableFiltersHook()

  const trimmedSubstrFilter = useCallback((filterValue: string, item: string): boolean => {
    const trimmedFilterValue = filterValue.trim()
    const trimmedItem = item.trim()

    return trimmedFilterValue
      ? caseInsensitiveSubstr(trimmedItem, trimmedFilterValue)
      : !trimmedItem //FilterText "Пусто"
  }, [])

  //TODO:WMLN-84 запилить кастомный фильтр для дат. DatePicker.
  return useMemo(() => [
    {
      title: 'Дата счёта',
      dataIndex: 'invoiceTimestamp',
      key: 'invoiceTimestamp',
      sorter: (a, b) => defaultCompare(a.invoiceTimestamp, b.invoiceTimestamp),
      width: '110px',
      render: value => <InvoiceDateCell timestamp={value} />,
    },
    {
      title: 'Deadline',
      dataIndex: 'deadlineTimestamp',
      key: 'deadlineTimestamp',
      sorter: (a, b) => defaultCompare(a.deadlineTimestamp, b.deadlineTimestamp),
      width: '110px',
      render: value => <InvoiceDateCell timestamp={value} />,
    },
    {
      title: 'Клиент',
      dataIndex: 'clientName',
      key: 'clientName',
      sorter: (a, b) => caseInsensitiveCompare(a.clientName, b.clientName),
      width: '220px',
      render: value => <TextCell text={value} maxLine={1} />,
      filters: filters.clientName,
      onFilter: (value, record) => trimmedSubstrFilter(record.clientName, value as string),
      filterMultiple: true,
      filterSearch: true,
    },
    {
      title: 'AMO',
      dataIndex: 'amoId',
      key: 'amoId',
      sorter: (a, b) => defaultCompare(a.amoId, b.amoId),
      width: '100px',
      render: value => <AmoIdCell id={value} />,
      filters: filters.amoId,
      onFilter: (value, record) => {
        const filterValue = value as string
        return filterValue ? record.amoId === value : !record.amoId.trim()
      },
      filterMultiple: true,
      filterSearch: true,
    },
    {
      title: 'Цена за штуку для клиента, ₽',
      dataIndex: 'clientPrice',
      key: 'clientPrice',
      sorter: (a, b) => defaultCompare(a.clientPrice, b.clientPrice),
      width: '150px',
      render: (_, record) => <TextCell text={`${formatMoney(record.clientPrice)} ₽`} />,
    },
    {
      title: 'Наименование в счёте',
      dataIndex: 'invoiceName',
      key: 'invoiceName',
      width: '220px',
      sorter: (a, b) => caseInsensitiveCompare(a.invoiceName, b.invoiceName),
      render: value => <TextCell text={value} maxLine={2} />,
    },
    {
      title: 'Предполагаемый магазин',
      dataIndex: 'supposedStore',
      key: 'supposedStore',
      width: '200px',
      sorter: (a, b) => caseInsensitiveCompare(a.supposedStore, b.supposedStore),
      render: value => <TextCell text={value} />,
      filters: filters.supposedStore,
      onFilter: (value, record) => trimmedSubstrFilter(record.supposedStore, value as string),
      filterMultiple: true,
      filterSearch: true,
    },
    {
      title: 'Производитель',
      dataIndex: 'brand',
      key: 'brand',
      width: '170px',
      sorter: (a, b) => caseInsensitiveCompare(a.brand, b.brand),
      render: value => <TextCell text={value} />,
      filters: filters.brand,
      onFilter: (value, record) => trimmedSubstrFilter(record.brand, value as string),
      filterMultiple: true,
      filterSearch: true,
    },
    {
      title: 'Дата закупки',
      dataIndex: 'purchaseTimestamp',
      key: 'purchaseTimestamp',
      width: '130px',
      sorter: (a, b) => defaultCompare(a.purchaseTimestamp, b.purchaseTimestamp),
      render: value => <InvoiceDateCell timestamp={value} />,
    },
    {
      title: 'Предполагаемая страна',
      dataIndex: 'country',
      key: 'country',
      width: '200px',
      sorter: (a, b) => caseInsensitiveCompare(a.country, b.country),
      render: value => <TextCell text={value} />,
      filters: filters.country,
      onFilter: (value, record) => trimmedSubstrFilter(record.country, value as string),
      filterMultiple: true,
      filterSearch: true,
    },
    {
      title: 'Cat',
      dataIndex: 'catalogueId',
      key: 'catalogueId',
      width: '150px',
      sorter: (a, b) => caseInsensitiveCompare(a.catalogueId, b.catalogueId),
      render: value => <TextCell text={value} />,
    },
    {
      title: 'Количество',
      dataIndex: 'quantity',
      key: 'quantity',
      width: '120px',
      sorter: (a, b) => defaultCompare(a.quantity, b.quantity),
      render: (value: number) => <TextCell text={value.toString()} />,
    },
    {
      title: 'Комментарий от КП',
      dataIndex: 'proposalComment',
      key: 'proposalComment',
      width: '200px',
      sorter: (a, b) => defaultCompare(a.proposalComment, b.proposalComment),
      render: value => <TextCell text={value} maxLine={2} />,
    },
  ], [filters.amoId, filters.clientName])
}
import {PopupDefaultWrapper} from '../../../../../components/popups/popup-default-wrapper'
import {useAppDispatch} from '../../../../../hooks/storeHooks'
import {closeProcurementCreateOrderPopup} from '../../../../../store/procurement/create-order-popup-reducer'

export function CreateProcurementOrderPopupContent() {
  const dispatch = useAppDispatch()

  return <PopupDefaultWrapper
    title='Создать заказ'
    createContent={() => (
      <div className='d-flex flex-column'>
        CreateProcurementOrderPopupContent
      </div>
    )}
    buttonsInfo={[]}
    closeFn={() => dispatch(closeProcurementCreateOrderPopup())}
  />
}
import {PayloadAction, combineReducers, createSlice} from '@reduxjs/toolkit'
import {procurementCreateOrderPopupReducer} from './create-order-popup-reducer'

//undefined - опциональность, null - неккорректный ввод пользователя
export interface ProcurementItem {
	key: string,
	invoiceTimestamp: number|null,
	deadlineTimestamp: number|null,
	invoiceName: string,
	amoId: string,
	clientName: string,
	clientPrice: number,
  supposedStore: string,
  brand: string,
  purchaseTimestamp: number|null,
  country: string,
  catalogueId: string,
  quantity: number,
  proposalComment?: string,
}

interface ProcurementState {
	loading: boolean,
	data: Array<ProcurementItem>,
	selectedRowKeys: Array<string>,
	searchValue: string,
}

const initialState: ProcurementState = {
  loading: false,
  data: [],
  selectedRowKeys: [],
  searchValue: '',
}

const procurementTableSlice = createSlice({
  name: 'procurementTable',
  initialState,
  reducers: {
    setProcurementTableLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.loading = payload
    },
    loadProcurementTableData() {},
    setProcurementTableData: (state, { payload }: PayloadAction<Array<ProcurementItem>>) => {
      state.data = payload
    },
    setProcurementTableSelectedRowIds: (state, { payload }: PayloadAction<Array<string>>) => {
      state.selectedRowKeys = payload
    },
    setProcurementTableSearchValue: (state, { payload }: PayloadAction<string>) => {
      state.searchValue = payload
    },
  },
})

export const procurementReducer = combineReducers({
  createOrderPopup: procurementCreateOrderPopupReducer,
  table: procurementTableSlice.reducer,
})

export const {
  loadProcurementTableData,
  setProcurementTableData,
  setProcurementTableSelectedRowIds,
  setProcurementTableLoading,
  setProcurementTableSearchValue,
} = procurementTableSlice.actions
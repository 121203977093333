import {PopupDefaultWrapper} from '../../../../../components/popups/popup-default-wrapper'
import React, {useCallback} from 'react'

interface PopupProps {
	closeFn: () => void,
}

export function SplitProcurementTableItemPopup({
  closeFn,
}: PopupProps) {
  const createContent = useCallback(() => <div>SplitProcurementTableItemPopup</div>, [])

  return <PopupDefaultWrapper
    title='Разделить строку'
    createContent={createContent}
    buttonsInfo={{
      onCancel: closeFn,
      onSuccess: () => {
        //TODO:WMLN-84 Сохранение изменений
        closeFn()
      },
    }}
    closeFn={closeFn}
  />
}
import {Logger} from './Logger'

export function isNumber(value: string|number): boolean {
  if (typeof value === 'number') {
    return true
  }
  // @ts-ignore
  return !isNaN(parseFloat(value)) && !isNaN(value - 0)
}


export function formatMoney(money: number|string): string|false {
  if (!isNumber(money)) {
    Logger.error(`It's not number. Value: ${money}`)
    return false
  }

  const num = typeof money === 'number' ? money : Number(money)
  const str = num.toFixed(2).toString()
  const [firstPart, secondPart] = str.split('.')
  if (firstPart.length <= 3) {
    return `${firstPart},${secondPart}`
  }

  const remains = firstPart.length % 3
  let formattedFirstPart = firstPart.substring(0, remains)
  for (let i = remains; i < firstPart.length; i++) {
    formattedFirstPart += (i - remains) % 3 === 0 ? ` ${firstPart[i]}` : firstPart[i]
  }
  return `${formattedFirstPart},${secondPart}`
}
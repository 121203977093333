import {useSelector} from 'react-redux'
import {LinesEllipsisWithTooltip} from '../../../../components/lines-ellipsis-with-tooltip/lines-ellipsis-with-tooltip'
import {selectProcurementTableSearchValue} from '../../../../store/procurement/selectors'

interface InvoiceDateCellProps {
	text: string,
  maxLine?: number,
}

export function TextCell({
  text,
  maxLine = 1,
}: InvoiceDateCellProps) {
  const searchValue = useSelector(selectProcurementTableSearchValue)
  return <LinesEllipsisWithTooltip text={text} maxLine={maxLine} words={[searchValue]} />
}
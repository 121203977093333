import {createSlice} from '@reduxjs/toolkit'

interface CreateOrderPopupState {
  show: boolean,
  items: Array<CreateOrderPopupItemState>,
  orderFields: CreateOrderPopupOrderFields,
}

interface CreateOrderPopupOrderFields {
  storeId: string,
  orderTimestamp: number,
  orderId: string,
  providerId: string,
  currencyId: string,
  paymentMethodId: string,
  responsibleSCD: string,
  numberPO: string,
  tracking: string,
  managerContact: string,
  orderLink: string,
}

interface CreateOrderPopupItemState {
  id: string,
  brand: string,
  catalogue: string,
  originalName: string,
  invoiceName: string,
  itemCost: number,
  tax: number,
  deliveryCost: number,
  quantity: number,
  clientPrice: number,
  purchaseTimestamp: number,
}

const initialState: CreateOrderPopupState = {
  show: false,
  items: [],
  orderFields: {} as CreateOrderPopupOrderFields,
}

const createOrderPopupSlice = createSlice({
  name: 'createOrderPopup',
  initialState,
  reducers: {
    openProcurementCreateOrderPopup(state) {
      state.show = true
    },
    closeProcurementCreateOrderPopup(state) {
      state.show = false
    },
  },
})

export const procurementCreateOrderPopupReducer = createOrderPopupSlice.reducer

export const {
  openProcurementCreateOrderPopup,
  closeProcurementCreateOrderPopup,
} = createOrderPopupSlice.actions
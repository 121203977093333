import {createSelector} from '@reduxjs/toolkit'
import {RootState} from '../types'

export const selectProcurementTable = (state: RootState) => state.procurement.table
const selectCreateOrderPopup = (state: RootState) => state.procurement.createOrderPopup

export const selectProcurementTableData = createSelector(selectProcurementTable, state => state.data)
export const selectProcurementTableLoading = createSelector(selectProcurementTable, state => state.loading)
export const selectProcurementTableSelectedRowKeys = createSelector(selectProcurementTable, state => state.selectedRowKeys)
export const selectProcurementTableSearchValue = createSelector(selectProcurementTable, state => state.searchValue)

export const selectShowProcurementCreateOrderPopup = createSelector(selectCreateOrderPopup, state => state.show)
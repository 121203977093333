import {createSelector} from '@reduxjs/toolkit'
import {RootState} from '../types'

const theme = (state: RootState) => state.theme

export const selectDirection = createSelector(theme, state => state.direction)
export const selectLocale = createSelector(theme, state => state.locale)
export const selectTheme = createSelector(theme, state => state.currentTheme)
export const selectHeaderNavColor = createSelector(theme, state => state.headerNavColor)
export const selectNavType = createSelector(theme, state => state.navType)
export const selectIsMobile = createSelector(theme, state => state.isMobile)
export const selectNavCollapsed = createSelector(theme, state => state.navCollapsed)
export const selectSideNav = createSelector(theme, state => state.sideNavTheme)
import {CreateProcurementOrderPopup} from './popups/create-order-popup/index'
import {ProcurementActionsSection} from './procurement-actions-section'
import {ProcurementTable} from './procurement-table'

export function ProcurementView() {
  return (
    <div>
      <ProcurementActionsSection />
      <ProcurementTable />
      <CreateProcurementOrderPopup />
    </div>
  )
}
import axios from 'axios'
import * as _history from 'history'
import {notification} from 'antd'
import {AUTH_TOKEN} from '../config/AppConfig'

//TODO:improvements.
const history = _history.createBrowserHistory()
const apiCreator = axios.create({
  withCredentials: false,
})

// Config
const PROD_HOSTNAME = 'erp.appscience.ru'
const DEV_MODE_PAYLOAD_KEY = 'x-dev-mode'

const ENTRY_ROUTE = '/auth/login'
const TOKEN_PAYLOAD_KEY = 'x-auth-token'
const PUBLIC_REQUEST_KEY = 'public-request'

export function isProdHostname(): boolean {
  return window.location.hostname === PROD_HOSTNAME
}

// API Request interceptor
apiCreator.interceptors.request.use(config => {
  const jwtToken = localStorage.getItem(AUTH_TOKEN)

  if (!isProdHostname()) {
    config.headers![DEV_MODE_PAYLOAD_KEY] = 'true'
  }

  if (jwtToken && !config.headers![PUBLIC_REQUEST_KEY]) {
        config.headers![TOKEN_PAYLOAD_KEY] = jwtToken
  }

  if (!jwtToken && !config.headers![PUBLIC_REQUEST_KEY]) {

  }

  return config
}, error => {
  // Do something with request error here
  notification.error({message: 'Error'})
  Promise.reject(error)
})

// Api respone interceptor
apiCreator.interceptors.response.use(response => {
  if (response.headers[AUTH_TOKEN]) {
    console.log(response.headers)
    localStorage.setItem(AUTH_TOKEN, response.headers[AUTH_TOKEN])
  }

  return response
}, error => {

  const _location = window.location

  // Remove token and redirect
  if (error.response.status === 403) {
    localStorage.removeItem(AUTH_TOKEN)
    const redirect = `${_location.pathname}${_location.search}`
    history.push(`${ENTRY_ROUTE}?redirect=${redirect}`)
    window.location.reload()
  }

  let message = ''
  if (error.response.data && error.response.data.message) {
    message = error.response.data.message
  }
  if (error.response.data) {
    message = error.response.data
  }

  if (message) {
    notification.error({
      message,
      duration: 8,
    })
  }

  if (error.response.data.message) {
    return Promise.reject(error.response.data)
  }
  else {
    return Promise.reject({
      message: error.response.data,
    })
  }
})

export default apiCreator



import {ProcurementItem} from '../../store/procurement/reducer'
import apiCreator from '../apiCreator'
import {remapToProcurementItem} from './remapProcurementData'

export interface ProcurementTableResponseData {
	id: string,
	client_name: string,
	date: string,
	deadline_date: string,
	name: string,
	deal_id: string,
	final_price: number,
	brand: string,
	catalogue_id: string,
	country: string,
	proc_deadline_date: string,
	proposal_comments: string,
	quantity: string,
	store: string,
}

export const procurement = {
  getTableData(): Promise<Array<ProcurementItem>> {
    return apiCreator.get('https://api.appscience.ru/twin/get?status=procurement').then(res => {
      const rawItems: Array<ProcurementTableResponseData> = res.data.items
      return rawItems.map(remapToProcurementItem)
    })
  },
}
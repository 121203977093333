import {Button} from 'antd'
import React from 'react'
import {useAppDispatch} from '../../../hooks/storeHooks'
import {openProcurementCreateOrderPopup} from '../../../store/procurement/create-order-popup-reducer'
import {PlusCircleOutlined} from '@ant-design/icons'

export function CreateOrderButton() {
  const dispatch = useAppDispatch()

  return (
    <Button
      type='default'
      shape='round'
      size='middle'
      icon={<PlusCircleOutlined className='procurement create-order-button_icon' />}
      onClick={() => dispatch(openProcurementCreateOrderPopup())}
      className='procurement create-order-button'
    >
      Создать заказ
    </Button>
  )
}
import {createSlice} from '@reduxjs/toolkit'
import {THEME_CONFIG} from '../../config/AppConfig'
import {ScreenSize} from '../../hooks/useScreenSize'
import { ActionType } from '../types'

const initialState: typeof THEME_CONFIG = {
  ...THEME_CONFIG,
}

type DeviceType= ScreenSize

type DeviceModeType = ActionType<DeviceType>
type HeaderNavColor = ActionType<string>
type Theme = ActionType<string>
type NavigationCollapse = ActionType<boolean>

const themeSlice = createSlice({
  name: 'theme',
  initialState: {...initialState},
  reducers: {
    themeAction() {},

    setDeviceMode(state, actions: DeviceModeType) {
      state.deviceType = actions.payload
    },

    setHeaderNavColor(state, actions: HeaderNavColor) {
      state.headerNavColor = actions.payload
    },
    setTheme(state, actions: Theme) {
      state.currentTheme = actions.payload
    },
    setNavigationCollapse(state, action: NavigationCollapse) {
      state.navCollapsed = action.payload
    },
  },
})

export const themeReaducer = themeSlice.reducer
export const {
  themeAction,
  setDeviceMode,
  setHeaderNavColor,
  setTheme,
  setNavigationCollapse,
} = themeSlice.actions

import {createSlice} from '@reduxjs/toolkit'
import {ActionType} from '../types'
import {ProductType} from './types'

export type HandleInputProductType = {
	name: string,
	catalogue_id: string,
	cas_id: string,
	price: string,
	price_currency: string,
	shipping_conditions: string,
	package_size: string,
	vendor: string,
	partner_url: string,
	count: string,
	info_from_client: string
	input_by_handle?: boolean
}

const initialState = {
  initialValue: 'INITIAL_VALUE_TEST',
  analogs: [] as ProductType[],
  cities: [] as string[],
  products: [] as ProductType[],
  handleInputProduct: {
    name: '',
    catalogue_id: '',
    cas_id: '',
    price: '',
    price_currency: 'USD',
    shipping_conditions: 'ROOM',
    package_size: '',
    vendor: '',
    partner_url: '',
    count: '1',
    info_from_client: '',
  },
}

type CitiesAction = ActionType<string[]>
type ProductTypeAction = ActionType<ProductType[]>
type AnalogsTypeAction = ActionType<ProductType[]>

const searchSlice = createSlice({
  name: 'search',
  initialState: {...initialState},
  reducers: {
    getCitiesAction(state) { },
    getCitiesSuccessAction(state, action: CitiesAction) {
      state.cities = action.payload
    },
    setProductsList(state, action: ProductTypeAction) {
      state.products = action.payload
    },
    setAnalogsList(state, action: AnalogsTypeAction) {
      state.analogs = action.payload
    },
    setHandleInputProductAction(state, action: ActionType<HandleInputProductType>) {
      state.handleInputProduct = action.payload
    },
  },
})

export const searchReducer = searchSlice.reducer
export const {
  getCitiesAction,
  getCitiesSuccessAction,
  setProductsList,
  setHandleInputProductAction,
  setAnalogsList,
} = searchSlice.actions
